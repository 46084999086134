import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Pricing.css'; // Подключаем файл стилей

const pricingData = [
  {
    type: 'Индивидуальное',
    description: '12 занятий',
    price: '1млн. 100 тысяч сум',
    icon: 'student-online-cute-girl-glasses-sweater-studying-computer-writing-down-notes.jpg', // Путь к изображению
    shortDescription: 'Персональный подход к обучению для максимальной эффективности. Наслаждайтесь индивидуальными уроками, адаптированными под ваши потребности и цели.',
  },
  {
    type: 'Групповое (2 человека)',
    description: '12 занятий на каждого',
    price: '950 тысяч сум на человека',
    icon: 'crossed-formal-male-serious-career.jpg', // Путь к изображению
    shortDescription: 'Учитесь вместе с другом! Двое всегда лучше, чем один – это идеальная возможность для совместного роста и взаимоподдержки в изучении.',
  },
  {
    type: 'Групповое (3 человека)',
    description: '12 занятий на каждого',
    price: '850 тысяч сум на человека',
    icon: 'classmates-learning-together-group-study.jpg', // Путь к изображению
    shortDescription: 'Групповое обучение для трех человек – веселый и эффективный способ достичь своих целей. Делитесь опытом и учитесь в команде!',
  },
];

const Pricing = () => {
  return (
    <Container className="pricing-section">
      <div className="pricing-container">
        <h1 className="pricing-heading">Наши Услуги</h1>
        <Row>
          {pricingData.map((item, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="pricing-card">
                <Card.Img variant="top" src={item.icon} className="pricing-card-image" />
                <Card.Body className="pricing-card-body">
                  <Card.Title className="pricing-card-title">{item.type}</Card.Title>
                  <Card.Text>{item.shortDescription}</Card.Text>
                  <Card.Text className="pricing-card-price">{item.price}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <p className="pricing-note">Первый пробный урок бесплатный для всех типов обучения.</p>
      </div>
    </Container>
  );
};

export default Pricing;
