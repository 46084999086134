import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Info.css'; // Подключаем файл стилей

const Info = () => {
  return (
    <div className="info-banner">
      <Container className="info-content mt-5">
        <h3 className="info-heading">Что будет на консультации❔</h3>
        <Row>
          <Col md={4} className="mb-4">
            <Card className="info-card info-card-background" style={{ backgroundImage: 'url(10650283.jpg)' }}>
              <Card.Body>
                <Card.Title className="card-title">Определим ваш уровень языка</Card.Title>
                <Card.Text>
                  {/* Ваш текст описания здесь */}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="info-card info-card-background" style={{ backgroundImage: 'url(yellow-list-top-view.jpg)' }}>
              <Card.Body>
                <Card.Title className="card-title">Составим персональный план обучения</Card.Title>
                <Card.Text>
                  {/* Ваш текст описания здесь */}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="info-card info-card-background" style={{ backgroundImage: 'url(/cheerful-two-women-friends.jpg)' }}>
              <Card.Body>
                <Card.Title className="card-title">После записи на консультацию — подарок</Card.Title>
                <Card.Text>
                  Эксклюзивный доступ к<br/>
                  вебинарам от <br/>
                  наших спикеров,<br/>
                  как быстро <br/>
                  выучить английский<br/>
                  для конкретных задач:<br/> 
                  например, <br/>
                  перед переездом.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Info;
