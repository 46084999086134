import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './AboutUs.css'; // Подключаем файл стилей

const AboutUs = () => {
  return (
    <div className="about-us-banner">
      <Container className="about-us-content mt-5">
        <h3 className="about-heading">Подберём курс, который приведёт вас к цели✨</h3>
        <Row>
          <Col md={6} lg={4} className="mb-4">
            <Card className="info-card">
              <img src="1.jpg" alt="Интерактивные уроки" className="card-img" />
              <Card.Body>
                <Card.Title className="card-title">Интерактивные уроки</Card.Title>
                <Card.Text>
                  Долой скучные учебники и шаблонные тесты! Готовы перевернуть свое обучение английскому языку с ног на голову? Мы предлагаем интерактивные уроки, основанные на реальных медицинских ситуациях.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card className="info-card">
              <img src="Персонализированное_обучение.jpg" alt="Персонализированное обучение" className="card-img" />
              <Card.Body>
                <Card.Title className="card-title">Персонализированное обучение</Card.Title>
                <Card.Text>
                  Персональные рекомендации и адаптивная методика обучения помогут достичь желаемого результата в кратчайшие сроки, подстраиваясь под ваш уровень и цели.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card className="info-card">
              <img src="Иммерсивные_тренажеры.jpg" alt="Иммерсивные тренажеры" className="card-img" />
              <Card.Body>
                <Card.Title className="card-title">Иммерсивные тренажеры</Card.Title>
                <Card.Text>
                  Мы предлагаем иммерсивные тренажеры, имитирующие реальные медицинские беседы. Отработайте навыки общения с пациентами в смоделированных ситуациях.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card className="info-card">
              <img src="Профессиональный_контент.jpg" alt="Профессиональный контент" className="card-img" />
              <Card.Body>
                <Card.Title className="card-title">Профессиональный контент</Card.Title>
                <Card.Text>
                  Профессиональный контент, разработанный совместно с практикующими врачами, гарантирует актуальность и соответствие современным медицинским стандартам.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card className="info-card">
              <img src="20943566.jpg" alt="Современные методики" className="card-img" />
              <Card.Body>
                <Card.Title className="card-title">Современные методики</Card.Title>
                <Card.Text>
                  Используем только новейшие методики обучения, чтобы вы могли быстро и эффективно усваивать материал и применять знания на практике.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card className="info-card">
              <img src="3954939.jpg" alt="Гибкое расписание" className="card-img" />
              <Card.Body>
                <Card.Title className="card-title">Гибкое расписание</Card.Title>
                <Card.Text>
                  Мы предлагаем гибкое расписание занятий, которое подстроится под ваш график, чтобы обучение было удобным и не отвлекало от работы или личной жизни.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="cta-section">
          <h2 className="cta-heading">Готовы начать обучение?👇</h2>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
