import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import './TrialForm.css'; // Импортируем стили
import { toast } from 'react-toastify';

const TrialForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    preferredDate: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://api.doctorenglish.uz/contact', formData)
      .then(response => {
        toast.success('Ваша заявка отправлена!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          preferredDate: '',
        });
      })
      .catch(error => {
        toast.error('Ошибка отправки заявки:', error);
      });
  };

  return (
    <Container className="trial-form-container">
      <div className="trial-form-content">
        <div className="trial-form-description">
          <h1>Записывайтесь, чтобы использовать английский свободно</h1>
          <p>На бесплатной консультации мы определим ваш уровень языка и обсудим результаты, к которым хочется прийти — и покажем, как сделать это легко и с удовольствием.</p>
        </div>
        <div className="trial-form-form">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label></Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите ваше имя"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label></Form.Label>
              <Form.Control
                type="email"
                placeholder="Введите ваш email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label></Form.Label>
              <Form.Control
                type="tel"
                placeholder="+998(__)___-__-__"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPreferredDate">
              <Form.Label>Дата ознакомительного урока</Form.Label>
              <Form.Control
                type="date"
                name="preferredDate"
                value={formData.preferredDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button variant="submit" type="submit" className="btn-submit">
              Записаться
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default TrialForm;
