import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container } from 'react-bootstrap';
import './TestForm.css'; // Import your CSS file
import { toast } from 'react-toastify';

const TestForm = ({ level, user, onTestComplete }) => {
  const [answers, setAnswers] = useState(Array(10).fill(''));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isTestCompleted, setIsTestCompleted] = useState(false);

  const questionsByLevel = {
    beginner: [
      { question: 'What does "BMI" stand for in medicine?', options: ['Basic Metabolic Index', 'Body Mass Index', 'Biomedical Indicator', 'Baseline Measurement Index'] },
      { question: 'Which organ is responsible for filtering blood in the human body?', options: ['Liver', 'Kidney', 'Heart', 'Lung'] },
      { question: 'What is the medical term for high blood pressure?', options: ['Hypotension', 'Hypertension', 'Hyperactivity', 'Hypoventilation'] },
      { question: 'Which vitamin is essential for the absorption of calcium?', options: ['Vitamin A', 'Vitamin B12', 'Vitamin C', 'Vitamin D'] },
      { question: 'What is the medical term for inflammation of the joints?', options: ['Arthritis', 'Bronchitis', 'Dermatitis', 'Appendicitis'] },
      { question: 'Which part of the brain controls balance and coordination?', options: ['Cerebrum', 'Cerebellum', 'Brainstem', 'Hypothalamus'] },
      { question: 'What is the medical term for a heart attack?', options: ['Myocardial infarction', 'Stroke', 'Arrhythmia', 'Angina'] },
      { question: 'Which disease is characterized by progressive memory loss?', options: ['Parkinson\'s disease', 'Alzheimer\'s disease', 'Multiple sclerosis', 'Epilepsy'] },
      { question: 'Which type of diabetes requires insulin injections?', options: ['Type 1 diabetes', 'Type 2 diabetes', 'Gestational diabetes', 'Prediabetes'] },
      { question: 'What is the medical term for a loss of consciousness?', options: ['Coma', 'Syncope', 'Seizure', 'Vertigo'] }
    ],
    pre_intermediate: [
      { question: 'What is the medical term for difficulty breathing?', options: ['Dyspnea', 'Hypoxia', 'Tachypnea', 'Apnea'] },
      { question: 'Which hormone regulates blood sugar levels?', options: ['Insulin', 'Glucagon', 'Estrogen', 'Testosterone'] },
      { question: 'What is the medical term for the voice box?', options: ['Thyroid', 'Larynx', 'Trachea', 'Bronchus'] },
      { question: 'Which gland produces insulin?', options: ['Pancreas', 'Thyroid', 'Adrenal', 'Pituitary'] },
      { question: 'What is the medical term for low body temperature?', options: ['Hypothermia', 'Hyperthermia', 'Hypoxia', 'Hypertension'] },
      { question: 'Which blood cells are responsible for oxygen transport?', options: ['White blood cells', 'Platelets', 'Red blood cells', 'Plasma'] },
      { question: 'What is the medical term for a bruise?', options: ['Laceration', 'Contusion', 'Abrasion', 'Incision'] },
      { question: 'Which vitamin is essential for blood clotting?', options: ['Vitamin A', 'Vitamin C', 'Vitamin K', 'Vitamin E'] },
      { question: 'What is the medical term for a severe headache?', options: ['Migraine', 'Concussion', 'Aneurysm', 'Hemorrhage'] },
      { question: 'Which condition is characterized by irregular heart rhythms?', options: ['Hypertension', 'Arrhythmia', 'Angina', 'Heart murmur'] }
    ],
    intermediate: [
      { question: 'What is the medical term for inflammation of the liver?', options: ['Hepatitis', 'Cirrhosis', 'Nephritis', 'Appendicitis'] },
      { question: 'Which hormone is responsible for regulating metabolism?', options: ['Insulin', 'Thyroxine', 'Epinephrine', 'Testosterone'] },
      { question: 'What is the medical term for a blood clot?', options: ['Thrombosis', 'Hemorrhage', 'Embolism', 'Aneurysm'] },
      { question: 'Which organ produces bile to aid in digestion?', options: ['Gallbladder', 'Pancreas', 'Liver', 'Small intestine'] },
      { question: 'What is the medical term for abnormal heart rhythm?', options: ['Tachycardia', 'Bradycardia', 'Arrhythmia', 'Fibrillation'] },
      { question: 'Which vitamin is essential for vision in dim light?', options: ['Vitamin A', 'Vitamin B12', 'Vitamin C', 'Vitamin D'] },
      { question: 'What is the medical term for the voice box?', options: ['Larynx', 'Pharynx', 'Trachea', 'Esophagus'] },
      { question: 'Which condition is characterized by chronic inflammation of joints?', options: ['Rheumatoid arthritis', 'Osteoarthritis', 'Gout', 'Bursitis'] },
      { question: 'What is the medical term for a benign tumor of glandular tissue?', options: ['Adenoma', 'Carcinoma', 'Sarcoma', 'Lymphoma'] },
      { question: 'Which part of the brain regulates body temperature and hunger?', options: ['Hypothalamus', 'Thalamus', 'Cerebellum', 'Medulla oblongata'] }
    ]
  };

  const questions = questionsByLevel[level] || [];

  const handleChange = (e, index) => {
    const newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure all questions are answered before submission
    if (answers.includes('')) {
      toast.error('Пожалуйста, ответьте на все вопросы.');
      return;
    }

    setIsTestCompleted(true);

    axios.post(`https://api.doctorenglish.uz/test/${level}`, { answers, user })
      .then(response => {
        toast.success(`Вы ответили правильно на: ${response.data.score} вопросов`);

        // Create a link element
        const link = document.createElement('a');
        link.href = '/doctinory.pdf'; // Ensure the file is in the public folder
        link.download = 'doctinory.pdf'; // The name of the file to download

        // Append link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger download
        link.click();

        // Remove link from the body
        document.body.removeChild(link);

        onTestComplete();
      })
      .catch(error => console.error(error));
  };

  return (
    <Container className="test-form-container">
      <h3 className="test-form-title">Уровень: {level}</h3>
      <Form onSubmit={handleSubmit}>
        <div className="question-group">
          <p>Вопрос {currentQuestionIndex + 1} из {questions.length}</p>
          {questions.length > 0 && (
            <>
              <Form.Label className="question-label">{questions[currentQuestionIndex].question}</Form.Label>
              {questions[currentQuestionIndex].options.map((option, i) => (
                <Form.Check
                  type="radio"
                  label={option}
                  name={`question-${currentQuestionIndex}`}
                  value={option}
                  checked={answers[currentQuestionIndex] === option}
                  onChange={(e) => handleChange(e, currentQuestionIndex)}
                  className="option-check"
                  key={i}
                />
              ))}
            </>
          )}
        </div>
        <div className="button-group">
          {currentQuestionIndex > 0 && (
            <Button variant="secondary" type="button" onClick={handlePreviousQuestion} className="submit-button">
              Предыдущий
            </Button>
          )}
          {currentQuestionIndex < questions.length - 1 ? (
            <Button variant="primary" type="button" onClick={handleNextQuestion} className="submit-button">
              Следующий
            </Button>
          ) : (
            <Button variant="primary" type="submit" className="submit-button">
              🎁 Получить подарок 🎁
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default TestForm;
