import React, { useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';
import { FaEllipsisV } from 'react-icons/fa';
import './Header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Navbar className="navbar-custom" fixed="top">
            <Container className="navbar-content">
                <Navbar.Brand className="navbar-brand-container">
                    <img src="/docenglishlogo.png" alt="Logo" className="logo" />
                </Navbar.Brand>
                <button className="navbar-toggle" onClick={toggleMenu}>
                    <FaEllipsisV />
                </button>
                <Nav className={`nav-links-container ${menuOpen ? 'active' : ''}`}>
                    <Nav.Link as={ScrollLink} to="registration" spy={true} smooth={true} duration={500}>
                        <Button className="consultation-button">Записаться на консультацию</Button>
                    </Nav.Link>
                    <Nav.Link as={ScrollLink} to="about" spy={true} smooth={true} duration={500}>
                        <h5>О нас</h5>
                    </Nav.Link>
                    <Nav.Link as={ScrollLink} to="pricing" spy={true} smooth={true} duration={500}>
                        <h5>Стоимость обучения</h5>
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default Header;
