import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Header from './Header';
import RegistrationForm from './RegistrationForm';
import TestForm from './TestForm';
import AboutUs from './AboutUs';
import Pricing from './Pricing';
import TrialForm from './TrialForm'; // Импортируем новый компонент
import { Link as ScrollLink, Element } from 'react-scroll';
import './App.css'; // Подключаем стили
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Импорт стилей для уведомлений

import Info from './Info'; // Импортируйте новый компонент

function App() {
  const [registered, setRegistered] = useState(false);
  const [userData, setUserData] = useState({});
  const [testCompleted, setTestCompleted] = useState(false);

  const handleRegistrationSuccess = (user) => {
    setUserData(user);
    setRegistered(true);
  };

  const handleTestComplete = () => {
    setTestCompleted(true);
    toast.success('Тест успешно пройден!');
  };

  return (
    <div className="App">
      <Header />
      <div className="App-content">
        <Container className="mt-5">
          <Element name="registration">
            {!registered && (
              <section className="section registration-section">
                <RegistrationForm onSuccess={handleRegistrationSuccess} />
                {/* Добавляем компонент Info под формой регистрации */}
                <Info />
              </section>
            )}
          </Element>
          {registered && !testCompleted && (
            <Element name="test">
              <section className="section test-section">
                <TestForm level={userData.level} user={userData} onTestComplete={handleTestComplete} />
              </section>
            </Element>
          )}
          <Element name="about">
            <section className="section about-section">
              <AboutUs />
            </section>
          </Element>
          <Element name="trial">
            <section className="section trial-section">
              <TrialForm /> {/* Добавляем форму пробного урока */}
            </section>
          </Element>
          <Element name="pricing">
            <section className="section pricing-section">
              <Pricing />
            </section>
          </Element>
        </Container>
      </div>
      <footer className="footer p-3 mt-5">
        <div className="social-icons">
          <a href="https://t.me/doctors_english" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTelegram} size="2x" />
          </a>
          <a href="https://www.instagram.com/doctorenglish_uz?igsh=MXF4NzV4aWRoemcxeA==" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://www.facebook.com/doctorenglishuz?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://youtube.com/@doctorenglishuz?si=vXIUMSjMBN4Wyii2" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
        </div>
      </footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;